import { default as _91patientId_93iwKJPWJoYEMeta } from "/opt/render/project/src/webapp/pages/audit-summary/[patientId].vue?macro=true";
import { default as _91patientId_93mgIBXQvlLjMeta } from "/opt/render/project/src/webapp/pages/credo-encounter-data/[patientId].vue?macro=true";
import { default as forgot_45passworddd3IOcCPCPMeta } from "/opt/render/project/src/webapp/pages/forgot-password.vue?macro=true";
import { default as indexBEpTmHr6lUMeta } from "/opt/render/project/src/webapp/pages/index.vue?macro=true";
import { default as loginDivx0bOx8qMeta } from "/opt/render/project/src/webapp/pages/login.vue?macro=true";
import { default as _91patientId_93uUMvkenvRaMeta } from "/opt/render/project/src/webapp/pages/no-records/[patientId].vue?macro=true";
import { default as charts9LHhNh5NSkMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/patient/charts.vue?macro=true";
import { default as labsXnje4b7lyBMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/patient/labs.vue?macro=true";
import { default as medications1Ni0e8K8UbMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/patient/medications.vue?macro=true";
import { default as vitalsLM16tI0FucMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/patient/vitals.vue?macro=true";
import { default as patientZurlpfJvdFMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/patient.vue?macro=true";
import { default as _91fileId_938HlGAgKjvzMeta } from "/opt/render/project/src/webapp/pages/patient/[patientId]/pdf-viewer/[fileId].vue?macro=true";
import { default as request_45patientsfblF3zoSsMeta } from "/opt/render/project/src/webapp/pages/request-patient.vue?macro=true";
import { default as reset_45passwordTfDjoht8fSMeta } from "/opt/render/project/src/webapp/pages/reset-password.vue?macro=true";
import { default as signupyuKdTEtMokMeta } from "/opt/render/project/src/webapp/pages/signup.vue?macro=true";
import { default as callbackP5ZD4LNuH3Meta } from "/opt/render/project/src/webapp/pages/smart/callback.vue?macro=true";
import { default as launch9wQMkF3LTiMeta } from "/opt/render/project/src/webapp/pages/smart/launch.vue?macro=true";
import { default as testOqDlBt3Ec1Meta } from "/opt/render/project/src/webapp/pages/test.vue?macro=true";
export default [
  {
    name: "audit-summary-patientId",
    path: "/audit-summary/:patientId()",
    meta: _91patientId_93iwKJPWJoYEMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/audit-summary/[patientId].vue")
  },
  {
    name: "credo-encounter-data-patientId",
    path: "/credo-encounter-data/:patientId()",
    meta: _91patientId_93mgIBXQvlLjMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/credo-encounter-data/[patientId].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passworddd3IOcCPCPMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/webapp/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginDivx0bOx8qMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/login.vue")
  },
  {
    name: "no-records-patientId",
    path: "/no-records/:patientId()",
    meta: _91patientId_93uUMvkenvRaMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/no-records/[patientId].vue")
  },
  {
    name: "patient-patientId-patient",
    path: "/patient/:patientId()/patient",
    meta: patientZurlpfJvdFMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/patient.vue"),
    children: [
  {
    name: "patient-patientId-patient-charts",
    path: "charts",
    meta: charts9LHhNh5NSkMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/patient/charts.vue")
  },
  {
    name: "patient-patientId-patient-labs",
    path: "labs",
    meta: labsXnje4b7lyBMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/patient/labs.vue")
  },
  {
    name: "patient-patientId-patient-medications",
    path: "medications",
    meta: medications1Ni0e8K8UbMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/patient/medications.vue")
  },
  {
    name: "patient-patientId-patient-vitals",
    path: "vitals",
    meta: vitalsLM16tI0FucMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/patient/vitals.vue")
  }
]
  },
  {
    name: "patient-patientId-pdf-viewer-fileId",
    path: "/patient/:patientId()/pdf-viewer/:fileId()",
    meta: _91fileId_938HlGAgKjvzMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/patient/[patientId]/pdf-viewer/[fileId].vue")
  },
  {
    name: "request-patient",
    path: "/request-patient",
    component: () => import("/opt/render/project/src/webapp/pages/request-patient.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordTfDjoht8fSMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/reset-password.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupyuKdTEtMokMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/signup.vue")
  },
  {
    name: "smart-callback",
    path: "/smart/callback",
    meta: callbackP5ZD4LNuH3Meta || {},
    component: () => import("/opt/render/project/src/webapp/pages/smart/callback.vue")
  },
  {
    name: "smart-launch",
    path: "/smart/launch",
    meta: launch9wQMkF3LTiMeta || {},
    component: () => import("/opt/render/project/src/webapp/pages/smart/launch.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: testOqDlBt3Ec1Meta || {},
    component: () => import("/opt/render/project/src/webapp/pages/test.vue")
  }
]