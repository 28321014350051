<script setup lang="ts">
import { useLoadingIndicator } from '#imports'

const loadingIndicator = useLoadingIndicator({
  throttle: 200,
  duration: 2000,
})
</script>

<template>
  <div v-if="loadingIndicator.isLoading.value" class="page-loader">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
      <path
        fill="#fff"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="2s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>

<style scoped>
svg {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;
  z-index: 999999;
}
</style>
